
<template>
    <div>
      <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
      <ListPage
        ref="appTable"
        :endpoint="apiCall"
        :headers="headers"
        title="Brands"
        :showActionsColumn="true"
        :showAddButton="isAllowed('createBrand')"
        :showEditButton="isAllowed('editBrand')"
        :showDeleteButton="isAllowed('deleteBrand')"
        :showViewButton="isAllowed('viewBrand')"
        @add="navigateTo('/app/brands/0')"
        @edit="navigateTo(`/app/brands/${$event._id}`)"
        @delete="deleteBrand($event._id)"
        @view="navigateTo(`/app/brands/view/${$event._id}`)"
      >
        <!-- Header Section -->
        <template v-slot:extended-action-buttons>
          <import-file-button importData="brands" class="ml-2"></import-file-button>
        </template>
  
        <!-- Mobile View -->
        <template v-slot:item="{ item }" v-if="isMobile">
          <brand-card :brand="item" :icons="icons" @delete="removeItem"></brand-card>
          <v-divider></v-divider>
        </template>
  
        <!-- Web / Tablet View -->
        <template v-slot:active="{ item }" v-if="isTabletAndUp">
          <v-chip small :class="item.active ? 'status-active' : 'status-disabled'">
            {{ item.active ? 'Active' : 'Disabled' }}
          </v-chip>
        </template>

      </ListPage>
      <alert-message v-if="msg" :error="msg"></alert-message>
    </div>
  </template>
  
  <script>
  import ActionButton from '@/components/ActionButton';
  import appConstants from '@/utils/appConstants';
  import BrandCard from '@/components/BrandResponsiveCard';
  import ImportFileButton from '@/components/ImportFileButton';
  import ListPage from '@/components/common/ListPage.vue';
  
  export default {
    components: {
      ActionButton,
      BrandCard,
      ImportFileButton,
      ListPage,
    },
    data() {
      return {
        msg: '',
        msgType: '',
        search: '',
        headers: [
          { text: 'Title', value: 'title' },
          { text: 'Active', value: 'active' },
          { text: 'Actions', value: 'action', align: 'right' },
        ],
        items: [],
        icons: [
          { name: 'mdi-eye', path: '/app/brands/view/', permission: 'viewBrand' },
          { name: 'mdi-pencil', path: '/app/brands/', permission: 'editBrand' },
          { name: 'mdi-delete', permission: 'deleteBrand' },
        ],
        apiCall: appConstants.BRANDS_API,
      };
    },
    methods: {
      async deleteBrand(id) {
        try {
          await this.deleteItem(
            'Are you sure you want to delete this Brand?',
            appConstants.BRANDS_API + '/' + id
          );
          this.items.splice(this.items.findIndex((rec) => rec._id == id), 1);
          this.$refs.appTable.loadData(); // Refresh the ListPage data
        } catch (error) {
          this.handleError(error);
          this.msg = error.response.data;
        }
      },
      removeItem(id) {
        this.deleteBrand(id);
      },
     
      navigateTo(path) {
        this.$router.push(path);
      },
    },
  };
  </script>
  
  <style scoped>
  .status-active {
    background-color: green !important;
    color: white !important;
    font-weight: 700;
  }
  
  .status-disabled {
    background-color: gray !important;
    color: white !important;
    font-weight: 700;
  }
  </style>
  